import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import Config from "../../../Config";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Console } from 'console';


interface IProps {
    currentPlayer: any;
    hash: string;
    secret: string;
    name: string;
    type: string;
    value: any;
    maps: any;
    mapsAvailable: any;
    champions: any;
    championsAvailable: any;
    next?: boolean;
    dateutc: any;
    checked: boolean;
    matchCreate: any;
    secondsConfig: any;
    minutesConfig:any;
    matchSteps:any;
    connection: any;
    waitingon: string;
}

class Veto extends Component<IProps> {

    state = {
        stepValue: 9999,
        refreshInterval :-1,
        hide: false,
    }
   
    componentDidMount() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(formName: string, event: any) {
        this.setState({ stepValue: parseInt(event.target.value) });
    }

    handleSubmit(event: any) {
        event.preventDefault();

        localStorage.setItem("reset", "true")

        const { currentPlayer, hash, secret } = this.props;
        const { stepValue } = this.state;

            if(this.props.connection){
                const json =  { hash: hash, playerId: currentPlayer.id, value: stepValue, secret: secret }
                this.props.connection.invoke("Step", JSON.stringify(json)).then(() => {
                    console.log("Step!"); 
                
                });
            }
    }
          

    render() {
        const { currentPlayer, name, value, next, maps, champions, mapsAvailable, championsAvailable, waitingon } = this.props;
  
    
        let { type } = this.props;
        let css;
        let icon = { icon: faCircleNotch, color: 'text-gray-300' };
   
  
        if (!type)
            type = 'map_pick';

        let types = type.split('_');

        if (!types)
            types = ['map', 'ban'];

        if (!types[0])
            types[0] = 'map';

        if (!types[1])
            types[1] = 'ban';

        switch (types[1]) {
            case 'ban':
                css = 'background-color-red';
                icon = { icon: faTimes, color: '#fff' };
                break;
            case 'pick':
                css = 'background-color-green';
                icon = { icon: faCheck, color: '#000096' };
                break;
            case 'banfinal':
                css = 'background-color-green';
                icon = { icon: faCheck, color: '#000096' };
                types[1] = 'pick';
                break;
            default:
                css = 'border-orange-800';
        }

        return !next ? (
            <div className={`border-2 border-opacity-75 ${css} content-alt p-3 text-white shadow-md rounded-lg mb-4`}>
                <FontAwesomeIcon icon={icon.icon as IconProp} className={icon.color} /> <span className="font-semibold">{name}</span> has {types[1] === 'ban' ? 'banned' : 'picked'} <span className="font-semibold">{types[0] === 'map' ? maps[value].name : champions[value].name }</span>.
            </div>
        
        ) : (
            <div>
                <div className={`border-2 border-yellow-800 content-alt p-3 text-white shadow-md rounded-lg mb-4`}>
                    <FontAwesomeIcon icon={faCircleNotch as IconProp} spin /> Waiting on <span className="font-semibold">{waitingon}</span> to {types[1]} a {types[0]}.
                </div>
                <div>
                    {name === currentPlayer.name && (
                        <div className={"border-t border-gray-600 pt-4"}>
                            <div className={"text-white font-semibold mb-2"}>
                                It is your turn to {types[1]} a {types[0]}.
                            </div>
                   
                            <form method="post" onSubmit={this.handleSubmit}>
                                {types[0] === 'champ' ? (
                               
                                    <select disabled={ this.props.checked } className={`w-full p-2 bg-gray-800 border-2 border-gray-800 text-gray-300 placeholder:text-gray-400 focus:border-${css}-800 focus:outline-none`} onChange={(e: any) => this.handleChange("stepValue", e)} required>
                                        <option value="9999" selected>Select</option>
                                        {championsAvailable.map((value:number) => (
                                            <option value={value}>{champions[value].name}</option>
                                        ))}
                                    </select>
                                ) : (
                                    (this.props.secondsConfig === 0 && this.props.minutesConfig === 0 ? <select  className={`w-full p-2 bg-gray-800 border-2 border-gray-800 text-gray-300 placeholder:text-gray-400 focus:border-${css}-800 focus:outline-none`} onChange={(e: any) => this.handleChange("stepValue", e)} required>
                                    <option value="9999" selected>Select</option>
                                    {mapsAvailable.map((value:number) => (
                                        <option value={value}>{maps[value].name}</option>
                                    ))}
                                </select>
                                 : <select disabled={ this.props.checked } className={`w-full p-2 bg-gray-800 border-2 border-gray-800 text-gray-300 placeholder:text-gray-400 focus:border-${css}-800 focus:outline-none`} onChange={(e: any) => this.handleChange("stepValue", e)} required>
                                    <option value="9999" selected>Select</option>
                                    {mapsAvailable.map((value:number) => (
                                        <option value={value}>{maps[value].name}</option>
                                    ))}
                                </select>)
                                   
                                )}
                                <button type="submit" className={`${this.state.hide === true && 'hidden'} w-full btn-medium btn-${types[1] === 'pick' ? 'green' : 'red'} mt-4`} 
                                onClick={() => { if (this.state.stepValue !== 9999) { this.setState({ hide: true }) }}}>{types[1].toLocaleUpperCase()}</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        )

    }
}

export default Veto;