import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isJSDocReturnTag, isTypeQueryNode } from 'typescript';

// Mapas imágenes
import ban_awoken from "../../../resource/maps/ban_Awoken.png"
import pick_awoken from "../../../resource/maps/pick_Awoken.png"
import ban_covenant from "../../../resource/maps/ban_Blood_Covenant.png"
import pick_covenant from "../../../resource/maps/pick_Blood_Covenant.png"
import ban_embrace from "../../../resource/maps/ban_Deep_Embrace.png"
import pick_embrace from "../../../resource/maps/pick_Deep_Embrace.png"
import ban_crucible from "../../../resource/maps/ban_crucible.png"
import pick_crucible from "../../../resource/maps/pick_crucible.png"
import ban_corrupted from "../../../resource/maps/ban_Corrupted_keep.png"
import pick_corrupted from "../../../resource/maps/pick_Corrupted_keep.png"
import ban_exile from "../../../resource/maps/ban_exile.png"
import pick_exile from "../../../resource/maps/pick_exile.png"
import ban_sarnath from "../../../resource/maps/ban_Ruins_of_Sarnath.png"
import pick_sarnath from "../../../resource/maps/pick_Ruins_of_Sarnath.png"
import pick_bloodrun from "../../../resource/maps/pick_blood_run.png"
import ban_bloodrun from "../../../resource/maps/ban_blood_run.png"
import pick_molten from "../../../resource/maps/pick_molten.png"
import ban_molten from "../../../resource/maps/ban_molten.png"
import pick_tempest from "../../../resource/maps/pick_tempest.png"
import ban_tempest from "../../../resource/maps/ban_tempest.png"
import pick_koth from "../../../resource/maps/pick_tower_of_koth.png"
import ban_koth from "../../../resource/maps/ban_tower_of_koth.png"  
import pick_vale from "../../../resource/maps/pick_Vale_of_Pnath.png"
import ban_vale from "../../../resource/maps/ban_Vale_of_Pnath.png"    
import pick_darkzone from "../../../resource/maps/pick_dark_zone.png"
import ban_darkzone from  "../../../resource/maps/ban_dark_zone.png"
import pick_cursedsoul from "../../../resource/maps/pick_cursed_soul.png"
import ban_cursedsoul from "../../../resource/maps/ban_cursed_soul.png"
import pick_insomnia from "../../../resource/maps/pick_Insomnia.png"
import ban_insomnia from "../../../resource/maps/ban_Insomnia.png"

//Champions Imágenes
import ban_Anarki from "../../../resource/champions/ban_Anarki.png"
import pick_Anarki from "../../../resource/champions/pick_Anarki.png"
import ban_Athena from "../../../resource/champions/ban_Athena.png"
import pick_Athena from "../../../resource/champions/pick_Athena.png"
import ban_BJ from "../../../resource/champions/ban_BJ.png"
import pick_BJ from "../../../resource/champions/pick_BJ.png"
import ban_Clutch from "../../../resource/champions/ban_Clutch.png"
import pick_Clutch from "../../../resource/champions/pick_Clutch.png"
import ban_DeathKnight from "../../../resource/champions/ban_Death-Knight.png"
import pick_DeathKnight from "../../../resource/champions/pick_Death-Knight.png"
import ban_DOOM_Slayer from "../../../resource/champions/ban_DOOM_Slayer.png"
import pick_DOOM_Slayer from "../../../resource/champions/pick_DOOM_Slayer.png"
import ban_Eisen from "../../../resource/champions/ban_Eisen.png"
import pick_Eisen from "../../../resource/champions/pick_Eisen.png"
import ban_Galena from "../../../resource/champions/ban_Galena.png"
import pick_Galena from "../../../resource/champions/pick_Galena.png"
import ban_keel from "../../../resource/champions/ban_keel.png"
import pick_keel from "../../../resource/champions/pick_keel.png"
import ban_Nyx from "../../../resource/champions/ban_Nyx.png"
import pick_Nyx from "../../../resource/champions/pick_Nyx.png"
import ban_Ranger from "../../../resource/champions/ban_Ranger.png"
import pick_Ranger from "../../../resource/champions/pick_Ranger.png"
import ban_Scalebearer from "../../../resource/champions/ban_Scalebearer.png"
import pick_Scalebearer from "../../../resource/champions/pick_Scalebearer.png"
import ban_Slash from "../../../resource/champions/ban_Slash.png"
import pick_Slash from "../../../resource/champions/pick_Slash.png"
import ban_Sorlag from "../../../resource/champions/ban_Sorlag.png"
import pick_Sorlag from "../../../resource/champions/pick_Sorlag.png"
import ban_Strogg from "../../../resource/champions/ban_Strogg.png"
import pick_Strogg from "../../../resource/champions/pick__Strogg.png"
import ban_Vizor from "../../../resource/champions/ban_Vizor.png"
import pick_Vizor from "../../../resource/champions/pick_Vizor.png"

//finals
import final_awoken from "../../../resource/maps/FINAL_awoke.png"
import final_blood_covenant from "../../../resource/maps/FINAL_blood_covenant.png"
import final_corrupted_keep from "../../../resource/maps/FINAL_corrupted_keep.png"
import final_crucible from "../../../resource/maps/FINAL_crucible.png"
import final_deep_embrace from "../../../resource/maps/FINAL_deep_embrace.png"
import final_exile from "../../../resource/maps/FINAL_exile.png"
import final_ruins_of_sarnath from "../../../resource/maps/FINAL_ruins_of_sarnath.png"


interface IProps {
    currentName: string | boolean;
    hash: string;
    secret: string;
    name: string;
    waitingon: string;
    type: string;
    value: any;
    maps: any;
    mapsAvailable: any;
    champions: any;
    championsAvailable: any;
    next?: boolean;
}

class Veto extends Component<IProps> {

    state = {
        stepValue: 9999,
        timer: 15,
        hide: false,
    }


    getMapImage(value: string | string[]){
      
        let { type } = this.props;

        if (!type)
    
        type = 'map_pick';

            let types = type.split('_');

            if (!types)
                types = ['map', 'ban'];

            if (!types[0])
                types[0] = 'map';

            if (!types[1])
                types[1] = 'ban';


        if(value.includes("awoken")){
            
            switch (types[1]) {
                case 'ban':
                    return ban_awoken
                case 'pick':
                  return pick_awoken
                case 'banfinal':
                  return final_awoken
                default:
                   return ban_awoken
            }
           
        }
        else if(value.includes('vale')){
            switch (types[1]) {
                case 'ban':
                    return ban_vale
                case 'pick':
                    return pick_vale
                case 'banfinal':
                    return final_deep_embrace
                default:
                   return ban_vale
            }
        }

        else if(value.includes('embrace')){
            switch (types[1]) {
                case 'ban':
                    return ban_embrace
                case 'pick':
                    return pick_embrace
                case 'banfinal':
                    return final_deep_embrace
                default:
                   return ban_embrace
            }
        }

        else if(value.includes('tempest')){
            switch (types[1]) {
                case 'ban':
                    return ban_tempest
                case 'pick':
                    return pick_tempest
                case 'banfinal':
                    return final_crucible
                default:
                   return ban_tempest
            }
        }
        else if(value.includes('crucible')){
            switch (types[1]) {
                case 'ban':
                    return ban_crucible
                case 'pick':
                    return pick_crucible
                case 'banfinal':
                    return final_crucible
                default:
                   return ban_crucible
            }
        }

        else if(value.includes('corrupted')){
            switch (types[1]) {
                case 'ban':
                    return ban_corrupted
                case 'pick':
                    return pick_corrupted
                case 'banfinal':
                    return final_corrupted_keep
                default:
                   return ban_corrupted
            }
        }

        else if(value.includes('covenant')){
            switch (types[1]) {
                case 'ban':
                    return ban_covenant
                case 'pick':
                  return pick_covenant
                case 'banfinal':
                    return final_blood_covenant
                default:
                   return ban_covenant
            }
        }

        else if(value.includes('blood_run')){
            switch (types[1]) {
                case 'ban':
                    return ban_bloodrun
                case 'pick':
                    return pick_bloodrun
                case 'banfinal':
                    return final_exile
                default:
                   return ban_bloodrun
            }
        }
        
        else if(value.includes('exile')){
            switch (types[1]) {
                case 'ban':
                    return ban_exile
                case 'pick':
                    return pick_exile
                case 'banfinal':
                    return final_exile
                default:
                   return ban_exile
            }
        }

        else if(value.includes('molten')){
            switch (types[1]) {
                case 'ban':
                    return ban_molten
                case 'pick':
                    return pick_molten
                case 'banfinal':
                    return final_exile
                default:
                   return ban_molten
            }
        }

        else if(value.includes('ruins')){
            switch (types[1]) {
                case 'ban':
                    return ban_sarnath
                case 'pick':
                    return pick_sarnath
                case 'banfinal':
                    return final_ruins_of_sarnath
                default:
                   return ban_sarnath
            }
        }

        else if(value.includes('cursed')){
            switch (types[1]) {
                case 'ban':
                    return ban_cursedsoul
                case 'pick':
                    return pick_cursedsoul
                case 'banfinal':
                    return final_ruins_of_sarnath
                default:
                   return ban_cursedsoul
            }
        }

        else if(value.includes('tower')){
            switch (types[1]) {
                case 'ban':
                    return ban_koth
                case 'pick':
                    return pick_koth
                case 'banfinal':
                    return final_crucible
                default:
                   return ban_koth
            }
        }

        else if(value.includes('insomnia')){
            switch (types[1]) {
                case 'ban':
                    return ban_insomnia
                case 'pick':
                    return pick_insomnia
                case 'banfinal':
                    return final_ruins_of_sarnath
                default:
                   return ban_insomnia
            }
        }
    }

    render() {


        const { name, value, next, maps, champions, waitingon } = this.props;

        let championsPicked = [pick_Nyx, pick_Anarki, pick_Slash, pick_Vizor, pick_Ranger, pick_Galena, pick_BJ, pick_DOOM_Slayer, pick_Strogg, pick_Eisen, pick_Scalebearer, pick_Clutch, pick_Sorlag, pick_keel, pick_Athena, pick_DeathKnight];

        let championsBanned = [ban_Nyx, ban_Anarki, ban_Slash, ban_Vizor, ban_Ranger, ban_Galena, ban_BJ, ban_DOOM_Slayer, ban_Strogg, ban_Eisen, ban_Scalebearer, ban_Clutch, ban_Sorlag, ban_keel, ban_Athena, ban_DeathKnight];

        let { type } = this.props;
        let css;
        if (!type)
            type = 'map_pick';

        let types = type.split('_');

        if (!types)
            types = ['map', 'ban'];

        if (!types[0])
            types[0] = 'map';

        if (!types[1])
            types[1] = 'ban';

        switch (types[1]) {
            case 'ban':
                css = 'border-ban';
                break;
            case 'pick':
                css = 'border-pick';
                break;
            case 'banfinal':
                css = 'border-final';
                break;
            default:
                css = 'border-orange-800';
        }

        
        return !next ? (

            types[0] === 'map' ?  (
                <div >
                    <div className={`  w-full ${types[0] === 'map' ? 'height-map' : 'h-24'} relative text-white shadow-md `} style={{
                        // backgroundImage: `url('${types[0] === 'map' ? this.getMapImage(mapsImage[value]) : championsImage[value]}')`,
                        // backgroundSize: 'fill',
                        // backgroundColor: '#555',
                    }}>
                        
                        <img alt='' src={types[0] === 'map' ? this.getMapImage(maps[value].image) : champions[value].image}></img>
                    </div>

       
                    <div className={"no-padding overflow-hidden"} style={{marginTop: -65}}>
                        <div className="relative h-32" style={{ maxWidth: 240}}>
                            <div className="flex h-full">
                                <div className="m-auto">
                                    <div style={{backgroundColor: '#1E1E1E'}} className={`${css}  b-4 text-base md:text-sm   px-4 py-1.5  uppercase`} >
                                    {/* <span className='uppercase'>{maps[value]}</span> */}
                                    <span className='uppercase'>{name}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    
                    </div>  
                    
                </div>

                
            )
            : 
                // si es jugador    
            (
              
                    <div className={` inline-flex w-full ${types[0] === 'map' ? 'height-map' : ''} relative text-white shadow-md `} style={{
                        maxHeight: 60
                    }}>

              
                          
                        <img alt='t' style={{maxWidth: 60, maxHeight: 60}} src={types[1] === 'ban' ? championsBanned[value] : championsPicked[value]}></img>
                        <div className='inline-flex center ' style={{marginLeft:10}}>  <span>{name}</span></div>
                      
                    </div>
              
            )

   
        ) : 
    
        types[0] === 'map' ?  (  
            <div className={`border-4 ${css} w-full ${types[0] === 'map' ? 'h-20' : 'h-24'} relative text-white shadow-md rounded-lg bg-white bg-opacity-10`}>
                <div className={"absolute bg-black bg-opacity-90 rounded px-2 py-0.5 bottom-2 left-2 text-sm"}>
                    
           
                    {types[0] === 'map' ? (
                        <span>
                            <FontAwesomeIcon icon={faSpinner as IconProp} className="mr-2" spin />
                            Waiting on <span className="font-semibold">{waitingon}</span>
                            {types[1] === 'ban' ? ' ban ' : ' pick '} a map
                        </span> 
                    ) : (
                        <div>
                             
                            <FontAwesomeIcon icon={faSpinner as IconProp} className="absolute -top-9 -right-7 text-3xl" spin />
                            <span>
                                {name}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        ) : (

                    // jugador waiting

                    
                        <div className={` inline-flex w-full ${types[0] === 'map' ? 'height-map' : ''} relative text-white shadow-md `} style={{
                            maxHeight: 60
                        }}>
                            <div style={{width: 64, margin: 0}} className="center">
                                <FontAwesomeIcon icon={faSpinner as IconProp} className="  text-3xl" spin />
                            </div>
                       
                            <div className='inline-flex center' style={{marginLeft:10}}>  <span>{name}</span></div>
                        
                        </div>
                 


        )
        
            

    }
}

export default Veto;