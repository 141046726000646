import React, { FormEvent, useEffect, useState } from 'react'
import FormInput from "../../form/FormInput";
import Config from "../../../Config";
import LoadingSpinner from "../../navigation/LoadingSpinner";
import logo from "../../../resource/logos/logo_create_match_3.png";

const Create = () => {
    const [ playerOne, setPlayerOne ] = useState<string>('');
    const [ playerTwo, setPlayerTwo ] = useState<string>('');
    const [ matchGame, setMatchGame ] = useState<number>(0);
    const [ matchCointoss, setMatchCointoss ] = useState<number>(0);
    const [ cointoss, setCointoss ] = useState<any>([]);
    const [ configs, setConfigs ] = useState<any>([]);


    const [ matchSeconds, setSeconds ] = useState<number>(30);
  
    localStorage.clear();

    useEffect(() => {
        fetch(`${Config.apiUrl}/home`)
            .then(response => response.json())
            .then(response => {
                setConfigs(response.types);
                setCointoss(response.cointoss);
                setMatchGame(response.types[0].id || 0);
            });
    }, []);


    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
 
         const requestOptions = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({ matchCointoss: matchCointoss, matchGame: matchGame, matchSeconds: matchSeconds, playerOne: playerOne, playerTwo: playerTwo })
         };
 
         fetch(`${Config.apiUrl}/create`, { ...requestOptions })
             .then(response => response.json())
             .then(data => {
                 if (data) {
                     if(data.ok !== '')
                         window.location.href =`/match/${data.data.hash}/${data.data.secret}/${data.data.player1Id}`;
                 }
             });
     }


    return configs.length ? (
        <div className={"flex create-screen"}>
            <div className={"w-full lg:w-1/3 m-auto"}>

                <div className='iconContainer p-8 center'>
                    <img className='center' src={logo} alt="KeenCon"/>
                </div>

                <div className={"bg-black bg-opacity-25 p-8"}>
                    
                    <form method={"post"} onSubmit={handleSubmit}>
                        <div className="pb-4 text-2xl text-center font-semibold text-white">
                            Create Match
                        </div>
                        <div className={"mb-4"}>
                            <div className="font-semibold text-base text-gray-200">Game</div>
                            <select className={"form-control"} onChange={(e) => setMatchGame(Number(e.target.value))} required>
                                {configs.map((row: any) => (
                                    <option key={row.id} value={row.id}>{row.name}</option>
                                ))}
                            </select>
                        </div>
                        <FormInput required={true}  type="text" name="playerOne" id="You" placeholder="PLAYER NAME 1" className={"mb-4"} onChange={(e) => setPlayerOne(String(e.target.value))} />
                        <FormInput required={true}  type="text" name="playerTwo" id="Opponent" placeholder="PLAYER NAME 2" className={"mb-4"} onChange={(e) => setPlayerTwo(String(e.target.value))} />
                        <div className={"mb-4"}>
                            <div className="font-semibold text-base text-gray-200">First Seed</div>
                            <select className={"form-control"} onChange={(e) => setMatchCointoss(Number(e.target.value))} required>
                                {cointoss.map((row: any) => (
                                    <option key={row.id} value={row.id}>{row.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>       
                            <FormInput name='Seconds' id='Seconds' defaultValue={30} type="number" placeholder=""   className={"mb-4"} onChange={(e) => setSeconds(Number(e.target.value))}  required={false} />                         
                        </div>

                        <div>
                            <label>*Select 0 seconds for no timelimit selection</label>
                        </div>

                        <div className={"text-right mt-5"}>
                            <button type="submit" className={"btn-medium btn-red"}>Submit</button>
                        </div>
                    </form>
                </div>

                <div className={"text-center p-3 uppercase text-sm text-white font-semibold my-6"}>
                    Our tool has been developed based on <a href="https://github.com/CameronCT/Qrindr" className="text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">Qrindr</a> by Gnik | &copy; KEENCON 2023
                </div>
            </div>
        </div>
    ) : <LoadingSpinner />
}

export default Create;