import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./components/pages/Home/Home";
import NavigationDesktop from "./components/navigation/NavigationDesktop";
import Match from "./components/pages/Match/Match";
import About from "./components/pages/About/About";
import Stream from "./components/pages/Stream/Stream";
import Create from "./components/pages/Create/Create";
import "@fontsource/chakra-petch";
import CreatePro from './components/pages/Create/CreatePro';

export const AppContext = React.createContext(60);

export const App = () => {
  return (
      <BrowserRouter>
            {/* <NavigationDesktop />  */}
            <div className={""}>
                <Routes>
                    <Route path="/" element={<Create />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/create" element={<Create />} />
                    <Route path="/keencon2023" element={<CreatePro />} />
                    <Route path="/match/:hash/:secret/:player" element={<Match />} />
                    <Route path="/match/:hash" element={<Match />} />
                    <Route path="/stream/:hash" element={<Stream />} />
                </Routes>
            </div>
            
      </BrowserRouter>
  );
};