import React, { useCallback, useContext } from 'react';
import { useRef, useEffect, useState } from 'react';
import Veto from "./Veto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt, faEye} from "@fortawesome/free-solid-svg-icons";
import Config from "../../../Config";
import LoadingSpinner from "../../navigation/LoadingSpinner";
import { useParams } from 'react-router';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import logo from "../../../resource/logos/logo_create_match_3.png";
import fondoMatch from "../../../resource/fondo_timer_players.png";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useLocation } from 'react-router-dom';
import { time } from 'console';



const Match = () => {

    const refreshInterval = useRef<NodeJS.Timer | null>(null);

    const refreshTimer = useRef<NodeJS.Timer | null>(null);

    const { hash, player, secret } = useParams<{ hash: string; player?: string; secret?: string; }>();

    const [data, setData ] = useState<any>({});
    const [select, setSelect ] = useState<boolean>(true);
    const [isLoaded, setIsLoaded ] = useState<boolean>(false);
    const [isReady, setReady ] = useState<boolean>(false);
    const [currentPlayer, setCurrentPlayer ] = useState<any>('');
    const [currentPlayerId, setCurrentPlayerId ] = useState<string>('');
    const [currentDate, setCurrentDate ] = useState<any>(null);

    const [next, setNext ] = useState<string>('');
    const [minutes, setMinutes ] = useState<any>(1);
    const [seconds, setSeconds ] = useState<any>(0);

    const [lastStepDate, setLastStepDate ] = useState<any>(null);
    const [secondsConfig, setSecondsConfig ] = React.useState(0);;
    const [minutesConfig, setMinutesConfig ] = React.useState(0);;
    const [checked, setChecked] = React.useState(false);

    const playNotificationSound = () => (document.getElementById('NotificationSound') as HTMLAudioElement)?.play();

    const [ connection, setConnection ] = useState<any>(null);
    const location = useLocation()

    document.onvisibilitychange = () => {
        if (document.visibilityState === "visible") {
        if (connection) {
                 var path = location.pathname.split("/");
                 var hash = path[2];
                 var secret = path[3];
                 var playerId = path[4];
                
                 const json =  {Hash: hash, Secret: secret, PlayerId: playerId, Value: 0}
                 connection.invoke("Ready", JSON.stringify(json)  ).then(() => {
                     console.log("Ready!")
                 });
             }
       }
    }

    function createHubConnection() {
        const options = {
            withCredentials: false,
        };
        const con = new HubConnectionBuilder()
          .withUrl(`${Config.apiUrl}/matchHub`, options)
          .withAutomaticReconnect()
          .build();
        setConnection(con);
      }
    
      useEffect(() => {
        createHubConnection();
      }, []);

      const setTimeLeft = useCallback ((segundosranscurridos, minutosTranscurridos, s , m) => {
    
        if(minutosTranscurridos === m && segundosranscurridos === s){
            setSeconds(0);
            setMinutes(0);
            return;
        }

        if(minutosTranscurridos === m  && segundosranscurridos >= s){
            setSeconds(0);
            setMinutes(0);
            return;
        }

        if(minutosTranscurridos >  m){
            setSeconds(0);
            setMinutes(0);
            return;
        }
       
        var secondsRestantes = 0;
        var minutesRestantes = 0;
       
        if(segundosranscurridos <= s ){
            secondsRestantes =  Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) ;
        }
        else{
            secondsRestantes = 60 - Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) -1;
        }

        console.log(minutosTranscurridos)
           
        setSeconds(secondsRestantes);
        setMinutes(minutesRestantes);
        
    }, [setMinutes, setSeconds])

    const timeLeft = useCallback ((segundosranscurridos, minutosTranscurridos, s , m, secondsConf, minutesConf) => {
       
        var secondsRestantes = 0;
        var minutesRestantes = 0;
       
        if(segundosranscurridos <= s ){
            secondsRestantes =  Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) ;
        }
        else{
            secondsRestantes = 60 - Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) -1;
        }

        setTimeLeft(secondsRestantes, minutesRestantes, secondsConf, minutesConf);

    }, [ setTimeLeft])

     useEffect(() => {
        var lastStepDate = "";
        var minConf = 0;
        var secConfig = 0;
        if (connection) {
            connection.start()
                .then(() => {
                    console.log('Connected!');
      
                    var path = location.pathname.split("/");
                    var hash = path[2];
                    var secret = path[3];
                    var playerId = path[4];
                    setCurrentPlayerId(playerId);
                    const json =  {Hash: hash, Secret: secret, PlayerId: playerId, Value: 0}
                    connection.invoke("Ready", JSON.stringify(json)  ).then(() => {
                        console.log("Ready!")
                    });

                    connection.on(
                        "MatchUpdate",
                        (response: any) => {
                     
                            if(response.ok){
                                 console.log(response.data);    
                                      
                                 var checked = playerId === response.data.playerOne.id ? response.data.playerOne.isReady : response.data.playerTwo.isReady;                  
                                 setChecked(checked);
                                 var currentPlayer = playerId === response.data.playerOne.id ? response.data.playerOne : response.data.playerTwo;
                                 setCurrentPlayer(currentPlayer);
                              
                                 if(response.data.nextStepIndex < response.data.steps.length ){
                                    var next = response.data.steps[response.data.nextStepIndex].player;
                                    setNext(next);
                                    var lsd = document.getElementById("nextStepIndex") as HTMLInputElement;
                                 
                                    if(lsd !== null && response.data.nextStepIndex!== parseInt( lsd.value) )
                                        playNotificationSound();
                                 } 
                            
                                 if(!response.data.isLastStep){
                                    var m = Math.floor(response.data.matchSeconds / 60);
                                    var s = response.data.matchSeconds % 60;
                                    setMinutesConfig(m);
                                    setSecondsConfig(s);
                                    minConf = m;
                                    secConfig = s;
   
                                    setMinutes(m);
                                    setSeconds(s);
                                 }
                              
                                 var readyPlayerOne = response.data.playerOne.isReady;
                                 var readyPlayerTwo = response.data.playerTwo.isReady;
                             
                                 var isReady = readyPlayerOne && readyPlayerTwo;
                                 setReady(isReady);
                                 setData({ ...response.data });
                                 setIsLoaded(true);

                                 if(readyPlayerOne && readyPlayerTwo && !response.data.isLastStep){
                                
                                    lastStepDate = response.data.lastStepDate;
                                    setLastStepDate(lastStepDate);
                                    connection.invoke("TimeCheck").then(() => {
                                        console.log("TimeCheck!"); 
                                    
                                    });

                                 }
                            }
                            else{
                                console.log(response.error);
                            }
                        }
                    );

                    connection.on(
                        "TimeCheck", 
                        (response: any) => {
                            var currentTime = new Date(response.timestamp);
                     
                            var lastStepDatee = new Date(lastStepDate);

                            var s1 = currentTime.getSeconds();
                            var s2 = lastStepDatee.getSeconds();

                            var m1 = currentTime.getMinutes();
                            var m2 = lastStepDatee.getMinutes();

                           timeLeft(s2, m2, s1, m1, secConfig, minConf);
                        }
                    );

                    connection.on(
                        "Error", 
                        (response: any) => {
                            console.log("Error");
                        }
                    );
                })
                .catch((e: any) => console.log('Connection failed: ', e));
        }
    }, [connection, location, timeLeft]);


    
    const submitRandom = useCallback ((stepValue) => {
        if(connection){
            const json =  { hash: hash, playerId: currentPlayerId, value: stepValue, secret: secret }
            connection.invoke("Step", JSON.stringify(json)).then(() => {
                console.log("Step Random!"); 
            
            });
        }
    }, [connection, hash, secret, currentPlayerId])

    const setRandom = useCallback (() => {
        let { type } = data.steps[data.nextStepIndex].type;
    
        if (!type)
            type = 'map_pick';
    
        let types = type.split('_');
    
        if (!types)
            types = ['map', 'ban'];
    
        if (!types[0])
            types[0] = 'map';
    
        if (!types[1])
            types[1] = 'ban';
    
            if(data.maps.available.length === 0)
                types[0] = 'champs'
    
        if(types[0] === 'map'){
              //map
           var max = data.maps.available.length - 1;
           var item = data.maps.available[Math.floor(Math.random()*max)];
    
            submitRandom(item);
        }
    
        else{
           //champ
           var max = data.champions.available.length - 1;
           var item = data.champions.available[Math.floor(Math.random()*max)];
            submitRandom(item);
        }
    },[data, submitRandom])


    useEffect(() => {
        if(secondsConfig === 0 && minutesConfig === 0 ){

           //por defecto
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }
        }
         else{
            //con timer
            const countdownPick = setInterval(() => {
          
         
                    if(data.nextStepIndex >= data.steps.length){
                        
                        clearInterval(countdownPick);

                        setMinutes(0);
                        setSeconds(0);


                        return;
                    }

                    if(data.playerOne.isReady &&  data.playerTwo.isReady ){
                    
                        if (seconds <= 0) {
                            if (minutes <= 0) {
                                console.log("timer is up!");
             
                                clearInterval(countdownPick);  
                               
                                var next = data.nextStepIndex <=  data.steps.length;
                                var nextPlayer = data.steps[data.nextStepIndex].player === 'playerOne' ? data.playerOne.id : data.playerTwo.id;
                                if(currentPlayer.id === nextPlayer && next){
                                 
                                   // setRandom();
                                }
                            
            
                            } else {
                                var x = minutes - 1;
                                setMinutes(x);
                                setSeconds(59);
                        
                            }
                        }
                        else {       
                                let x = (seconds - 1);
                                let y = minutes;
                                setMinutes(y);
                                setSeconds(x);
                            
                        }
                    }

                }, 1000);

            if (refreshTimer.current) {
                clearInterval(refreshTimer.current);
                        refreshTimer.current = null;
                        refreshTimer.current = countdownPick;
            }
        
            else
                refreshTimer.current = countdownPick;

            return () => clearInterval(countdownPick);
        }
    }, [ hash, data, seconds, minutes, setMinutes, setSeconds, minutesConfig, secondsConfig, setRandom]);

    
    const handleChangeCheck = (event:any) => {
        setChecked(!checked);
            
        if(connection){
            const json =  {Hash: hash, Secret: secret, PlayerId: currentPlayerId, Value: 1}
            connection.invoke("Ready", JSON.stringify(json)).then(() => {
                console.log("Check!")
            });
        }
    };

    const renderIndexTitle = (index: number) => {
        if (index === 0) return 'One';
        else if (index === 1) return 'Two';
        else if (index === 2) return 'Three';
        else if (index === 3) return 'Four';
        else if (index === 4) return 'Five';
        else if (index === 5) return 'Six';
        else if (index === 6) return 'Seven';
    }

    const renderSplit = (index: number) => {
        return (
           
            <div className={"content-bg next"}>
                <div>
                    {data.matchConfig && data.matchConfig.includes("2vs2 Best of 3") ? (
                        <div className={"text-center text-white text-lg font-semibold mb-4"}>
                            {"Ban for all maps"}
                    </div>
                    ) : (
                        <div className={"text-center text-white text-lg font-semibold mb-4"}>
                        {data.maps.items[data.maps.picked[index]].name}
                    </div>
                    )}
                </div>
                {data.steps.map(( value:any, key:number) => (
                    <div key={key}>
                        {
                        
                            key > data[`matchSplitMap${renderIndexTitle(index)}`] &&
                            key <= (data.nextStepIndex) &&
                            key <= data[`matchSplitMap${renderIndexTitle(index + 1)}`] &&
                            (
                                <Veto  
                                matchSteps={data.steps} 
                                connection={connection}
                                secondsConfig={secondsConfig}  
                                minutesConfig={minutesConfig} 
                                matchCreate={data.matchCreated} 
                                checked={!isReady}  
                                dateutc={data.LastStepDate}   
                                secret={matchSecret} hash={String(hash)} 
                                currentPlayer={currentPlayer}
                                name={data.steps[key].player === 'playerOne' ? data.playerOne.name : data.playerTwo.name} 
                                type={data.steps[key].type}  
                                value={data.steps[key].value} 
                                maps={data.maps.items} 
                                mapsAvailable={data.maps.available} 
                                champions={data.champions.items} 
                                waitingon={next === 'playerOne' ? data.playerOne.name : data.playerTwo.name}
                                championsAvailable={data.champions.available} 
                                next={key >= data.nextStepIndex} 
                                />
                            )
                        }
                    </div>
                ))}
            </div>
        )
    }

    let matchSecret = "";
    if (secret)
        matchSecret = secret;

    return isLoaded ? (
        <div className='w-10/12 mx-auto mt-10'>
              <input type='hidden' id="nextStepIndex" value={data.nextStepIndex} ></input>
            <div className={"grid grid-cols-1 md:grid-cols-2 mb-6"}>
                <div className="text-2xl">
                    <div className={"flex justify-start  space-x-2"}>
                        <img className='logo' src={logo} alt="KeenCon"/>  

                        <div className={"flex flex-col justify-center space-x-2"}>
                            <div className='ml-2'>
                                <span className={"font-bold-cp"}>{data.playerOne.name}</span> <span className={"text-gray-300 font-cp"}>vs</span> <span className={"font-bold-cp"}>{data.playerTwo.name}</span>
                            </div>

                            <div className={"text-xs  mt-1 font-cp"}>
                                {data.matchConfig} <span className={"px-2 "}>|</span>
                                {data.matchCointoss === '0' ? 'Random' : 'Manual'} Cointoss
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex justify-end md:justify-end space-x-2 items-center"}>


                { (secondsConfig === 0 && minutesConfig === 0 ) ? ``
                     : (
                <label>
                    <input type="checkbox" checked={checked} disabled={checked} onChange={handleChangeCheck} />
                    <span className='checkboxtext'>Ready</span>
                </label>
                )}



                { (secondsConfig === 0 && minutesConfig === 0 ) ? ``
                     : (
                        <div className="container" style={{width: "auto"}}>
                        <img src={fondoMatch} style={{maxHeight: "50px" , width: "100%"}} />
                     
                        <div className='centered' > { minutes === 0 && seconds === 0
                        ? <h1 style={{fontSize: "40px"}}> 00:00 </h1>
                        : <h1 style={{fontSize: "40px"}}> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1> 
                    }</div>
                    </div>
                    )}

                    {matchSecret !== "" && (
                        <a href={`/match/${hash}/${matchSecret}/${data.playerOne.id === currentPlayer.id ? data.playerTwo.id : data.playerOne.id}`} className={"btn-icon btn-red"}>
                            <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} />
                        </a>
                    )}
                    {data.matchSpec !== 0 && (
                        <a href={`/stream/${hash}`} className={"btn-icon btn-red"}>
                            <FontAwesomeIcon icon={faTwitch as IconProp} />
                        </a>
                    )}
                    
                    {/* <a href={`/match/${hash}`} className={"btn-icon btn-blue"}>
                        <FontAwesomeIcon icon={faEye as IconProp} />
                    </a> */}
                </div>
            </div>


            
            <div className={"grid grid-cols-4 gap-4"}>
            
                <div  className={`col-span-full ${(data.matchSplitMapOne && data.matchSplitMapOne !== 999) ? 'lg:col-span-1' : 'lg:col-span-2'}`}>
                    {data.steps.map(( value:any, key:number) => (
                        <div key={key}>
                            {key <= data.matchSplitMapOne && key <= (data.nextStepIndex) && (
                                <Veto matchSteps={data.steps} 
                                connection={connection}
                                secondsConfig={secondsConfig}  
                                minutesConfig={minutesConfig}  
                                matchCreate={data.matchCreated}   
                                checked={!isReady}  
                                dateutc={data.LastStepDate}  
                                secret={matchSecret} 
                                hash={String(hash)} 
                                currentPlayer={currentPlayer} 
                                name={data.steps[key].player === 'playerOne' ? data.playerOne.name : data.playerTwo.name} 
                                type={data.steps[key].type}   
                                value={data.steps[key].value} 
                                maps={data.maps.items} 
                                mapsAvailable={data.maps.available} 
                                champions={data.champions.items} 
                                championsAvailable={data.maps.available} 
                                next={key >= data.nextStepIndex} 
                                waitingon={next === 'playerOne' ? data.playerOne.name : data.playerTwo.name}
                                 />
                            )}
                        </div>
                    ))}
                    {(data.matchSplitMapOne === 999 && data.matchCopyPasta) && (
                        <div className={"content-bg next"}>
                            <div className={"text-center text-white text-lg font-semibold mb-4"}>
                                Copy Pasta
                            </div>
                            <div>
                                <div className={"text-white text-center content-alt p-4 w-full"}>{data.matchCopyPasta}</div>
                            </div>
                        </div>
                    )}
                </div>
                {(data.matchSplitMapOne && data.matchSplitMapOne !== 999) && (
                    <div className="col-span-full lg:col-span-2">
                        {data.nextStepIndex > data.matchSplitMapOne && renderSplit(0)}
                        {data.nextStepIndex > data.matchSplitMapTwo && renderSplit(1)}
                        {data.nextStepIndex> data.matchSplitMapThree && renderSplit(2)}
                        {data.nextStepIndex> data.matchSplitMapFour && renderSplit(3)}
                        {data.nextStepIndex > data.matchSplitMapFive && renderSplit(4)}
                        {data.nextStepIndex > data.matchSplitMapSix && renderSplit(5)}
                        {data.nextStepIndex > data.matchSplitMapSeven && renderSplit(7)}
                        {data.matchCopyPasta && (
                            <div className={"content-bg next"}>
                                <div className={"text-center text-white text-lg font-semibold mb-4"}>
                                    Copy Pasta
                                </div>
                                <div>
                                    <div className={"text-white text-center bg-gray-800 p-4 w-full"}>{data.matchCopyPasta}</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className={`col-span-full ${(data.matchSplitMapOne && data.matchSplitMapOne !== 999) ? 'lg:col-span-1' : 'lg:col-span-2'} px-2`}>
                    <div className={"content-bg next"}>
                        <div>
                            <div className={"text-lg font-semibold text-white"}>
                                Maps
                            </div>
                            <div>
                                <ul className={"list-decimal pl-8 text-gray-200 mt-4"}>
                                    <div className="mb-2">
                                        {data.maps.picked.map((value:any, key:number) => (
                                            <li key={value}>{data.maps.items[value].name}</li>
                                        ))}
                                    </div>
                                </ul>
                                <ul className={"list-disc pl-8 text-gray-200 mt-4"}>
                                    {data.maps.available.map((value:any, key:number) => (
                                        <li key={key}>{data.maps.items[value].name}</li>
                                    ))}
                                    {data.maps.taken.map((value:any, key:number) => (
                                        <li key={key} className={"line-through color-red"}>{data.maps.items[value].name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {data.maps.items[0] && (
                        <div className={"content-bg next"}>
                            <div>
                                <div className={"text-lg font-semibold text-white"}>
                                    Champions
                                </div>
                                <div>
                                <ul className={"list-decimal pl-8 text-gray-200 mt-4"}>
                                    <div className="mb-2">
                                        {data.champions.picked.map((value:any, key:number) => (
                                            <li key={value}>{data.champions.items[value].name}</li>
                                        ))}
                                    </div>
                                </ul>
                                    <ul className={"list-disc pl-8 text-gray-200 mt-4"}>
                                        {data.champions.available && data.champions.available.map((value:any, key:number) => (
                                            <li key={key}>{data.champions.items[value].name}</li>
                                        ))}
                                        {data.champions.taken.map((value:any, key:number) => (
                                            <li key={key} className={"line-through text-gray-600"}>{data.champions.items[value].name}</li>
                                        ))}


                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <audio id="NotificationSound" src="/assets/audio/ready.wav" crossOrigin="anonymous" preload="auto" />
        </div>
    ) : <LoadingSpinner />

}

export default Match;